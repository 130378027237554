import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { CSSProperties, HTMLProps, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { U21NoValue } from 'app/shared/u21-ui/components/display/U21NoValue';
import { getDOMProps, isRenderable } from 'app/shared/utils/react';
import { U21CopyButton } from 'app/shared/u21-ui/components/display/U21CopyButton';

export interface U21InfoItemProps
  extends Omit<HTMLProps<HTMLDivElement>, 'label' | 'ref' | 'value'> {
  children: ReactNode;
  enableCopy?: boolean;
  label: ReactNode;
  labelProps?: Omit<U21TypographyProps, 'as' | 'children'>;
  valueProps?: Omit<U21TypographyProps, 'as' | 'children'>;
  width?: CSSProperties['width'];
}

export const U21InfoItem = (props: U21InfoItemProps) => {
  const {
    children,
    enableCopy,
    label,
    labelProps,
    valueProps,
    width = '25%',
    ...rest
  } = props;
  return (
    <U21Spacer
      align="start"
      horizontal
      spacing={0.5}
      useFlexGap
      {...getDOMProps(rest)}
    >
      <LabelContainer $width={width}>
        {typeof label === 'string' || typeof label === 'number' ? (
          <U21Typography
            color="text.secondary"
            ellipsis
            variant="body2"
            {...labelProps}
          >
            {label}
          </U21Typography>
        ) : (
          label
        )}
      </LabelContainer>
      {(() => {
        if (!isRenderable(children)) {
          return <U21NoValue {...valueProps} />;
        }
        if (typeof children === 'string' || typeof children === 'number') {
          return (
            <U21Typography ellipsis variant="body2" {...valueProps}>
              {children}
            </U21Typography>
          );
        }
        return children;
      })()}
      {enableCopy &&
        (typeof children === 'string' || typeof children === 'number') && (
          <StyledU21CopyButton text={String(children)} />
        )}
    </U21Spacer>
  );
};

const LabelContainer = styled.div<{
  $width: CSSProperties['width'];
}>`
  min-width: ${(props) => props.$width};
  ${(props) => {
    if (!props.$width) {
      return css``;
    }
    const width =
      typeof props.$width === 'string' ? props.$width : `${props.$width}px`;

    return css`
      min-width: ${width};
      width: ${width};
    `;
  }}
`;

const StyledU21CopyButton = styled(U21CopyButton)`
  // align vertically with text
  margin-top: -4px;
`;
