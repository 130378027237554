import { HTMLProps } from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled, { css } from 'styled-components';

// SVG
import { ReactComponent as UploadIllustration } from 'app/shared/svg/upload.svg';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';

export interface U21FileUploaderProps
  extends Omit<HTMLProps<HTMLDivElement>, 'accept' | 'onDrop'> {
  onDrop: Required<Pick<DropzoneOptions, 'onDrop'>>['onDrop'];
  accept?: string[];
  disabled?: boolean;
  maxSize?: number;
  multi?: boolean;
  loading?: boolean;
}

export const U21FileUploader = (props: U21FileUploaderProps) => {
  const {
    accept,
    onDrop,
    multi = true,
    loading = false,
    disabled = false,
    maxSize,
    ...rest
  } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    disabled,
    maxSize,
    multiple: multi,
    onDrop,
    // useFsAccessApi prevents using `accept` to specify file extensions
    useFsAccessApi: false,
  });
  return (
    <StyledU21FileUploader
      {...getRootProps()}
      $disabled={disabled || loading}
      $isDragActive={isDragActive}
      {...getDOMProps(rest)}
    >
      <UploadIllustration />
      <input
        {...getInputProps()}
        aria-label="upload"
        disabled={disabled || loading}
      />
      <StyledText>
        {!loading && <StyledTopText>Drag &amp; Drop Files Here</StyledTopText>}
        {!loading && <span>Or click to select a file on your computer</span>}
        {loading && (
          <StyledLoadingContainer>
            <U21Loading loading={loading} variant="spinner" />
            <U21Typography>Uploading...</U21Typography>
          </StyledLoadingContainer>
        )}
      </StyledText>
    </StyledU21FileUploader>
  );
};

interface StyleProps {
  $disabled: boolean;
  $isDragActive: boolean;
}

const StyledU21FileUploader = styled.div<StyleProps>`
  max-height: 260px;
  width: 100%;
  aspect-ratio: 2.5;
  min-height: 150px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => {
    const {
      $disabled,
      $isDragActive,
      theme: {
        palette: { grey, colors },
      },
    } = props;
    return css`
      border: 1px ${grey[300]} solid;
      background: ${grey[$isDragActive ? 200 : 100]};
      color: ${grey[700]};

      ${$disabled
        ? css`
            cursor: default;
          `
        : css`
            &:hover {
              border: 2px ${colors.blue.main} solid;
            }
          `}
    `;
  }}
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 250px;
`;

const StyledTopText = styled.span`
  line-height: 30px;
  font-size: 20px;
  font-weight: 700;
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
